import './index.css';
import React from 'react';
import App from './App.tsx';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <div className='bg-gray-200'>
          <App />
        </div>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
