import { scrollToTop } from "../../utils/utils.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrolled = window.scrollY;
    if (scrolled > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isVisible ? (
    <>
      <button
        className="
          fixed bottom-6 right-3
          bg-[#0FA7E6] text-white font-bold
          text-lg md:text-2xl
          p-2 md:p-4
          w-12 md:w-16
          rounded-full shadow-lg transition-all
        "
        onClick={scrollToTop}
        aria-label="Back to Top"
      >
        <FontAwesomeIcon color="#fff" size="sm" icon={faAnglesUp} />
      </button>
      <button
        className="
          fixed bottom-6 left-3
          bg-[#0FA7E6] text-white font-bold
          text-lg md:text-2xl
          p-2 md:p-4
          w-12 md:w-16
          rounded-full shadow-lg transition-all
        "
        onClick={scrollToTop}
        aria-label="Back to Top"
      >
        <FontAwesomeIcon color="#fff" size="sm" icon={faAnglesUp} />
      </button>
    </>
  ) : null;
};

export default BackToTopButton;
