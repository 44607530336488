import pages from "../../pages";
import { IPage } from "../../pages/page.types.ts";
import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";

const Loading = () => <div>Loading...</div>;

const routes = pages.map((page: IPage) => (
  <Route
    key={page.name}
    path={page.path}
    element={
      <Suspense fallback={<Loading />}>
        <page.element />
      </Suspense>
    }
  />
));

const Router = () => <Routes>{routes}</Routes>;

export default Router;