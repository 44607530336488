import { IPage } from "./page.types.ts";
import { lazy, LazyExoticComponent } from "react";

const Dashboard: LazyExoticComponent<() => JSX.Element> = lazy(() => import("./Dashboard/Dashboard.tsx"));
const Article: LazyExoticComponent<() => JSX.Element> = lazy(() => import("./Article/Article.tsx"));

const pages: IPage[] = [
  { name: "Dashboard", path: "/", element: Dashboard },
  { name: "Article", path: "/article/:id", element: Article },
  { name: "Dashboard", path: "*", element: Dashboard },
]

export default pages;