import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchState } from "./store.types.ts";
import { format } from "date-fns";

const now = new Date();
const startDate = new Date(now.setDate(now.getDate() - 29));
const endDate = new Date();

const formattedStartDate = format(startDate, "yyyy-MM-dd");
const formattedEndDate = format(endDate, "yyyy-MM-dd");

export const initialState: SearchState = {
  StartDate: formattedStartDate,
  EndDate: formattedEndDate,
  Query: "Cybersecurity",
  Sentiment: "all"
};

// Create the search slice
export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state: SearchState, action: PayloadAction<SearchState>): void => {
      state.StartDate = action.payload.StartDate;
      state.EndDate = action.payload.EndDate;
      state.Query = action.payload.Query;
      state.Sentiment = action.payload.Sentiment;
    },
  },
});

// Export the actions
export const { setSearch } = searchSlice.actions;

// Export the reducer
export default searchSlice.reducer;