import Layout from "./layout/Layout/Layout.tsx";

const App = () => {
  return (
    <div className='h-screen bg-gray-100'>
      <Layout />
    </div>
  );
}

export default App;
