import AppRouter from "./AppRouter.tsx";
import BackToTopButton from "./BackToTopButton.tsx";
import { lazy } from "react";
const Header = lazy(() => import("../Header/Header.tsx"));
const SubHeader = lazy(() => import("../SubHeader/SubHeader.tsx"));

const Layout = () => {
  return (
    <div className="flex flex-col w-full">
      <header className="fixed h-16 z-10 w-full bg-white border border-gray-400">
        <Header/>
        <SubHeader/>
      </header>
      <main className="flex-1 justify-center w-full">
        <AppRouter/>
      </main>
      <BackToTopButton />
    </div>
  );
};

export default Layout;