import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimePeriodState} from "./store.types.ts";
import { format } from "date-fns";

const now = new Date();
const startDate = new Date(now.setDate(now.getDate() - 29));
const endDate = new Date();

const formattedStartDate = format(startDate, "yyyy-MM-dd");
const formattedEndDate = format(endDate, "yyyy-MM-dd");

export const initialState: TimePeriodState = {
  StartDate: formattedStartDate,
  EndDate: formattedEndDate,
  name: "30days",
};

// Create the Time Period slice
export const timePeriodSlice = createSlice({
  name: "timePeriod",
  initialState,
  reducers: {
    setTimePeriod: (state: TimePeriodState, action: PayloadAction<TimePeriodState>): void => {
      state.StartDate = action.payload.StartDate;
      state.EndDate = action.payload.EndDate;
      state.name = action.payload.name;
    },
  },
});

// Export the actions
export const { setTimePeriod } = timePeriodSlice.actions;

// Export the reducer
export default timePeriodSlice.reducer;